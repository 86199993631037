/* eslint-disable */
import clsx from 'clsx';
import PropTypes, { string } from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Grid, Button, Tooltip } from '@mui/material';
import { CalculatorsContext, DraftContext, ErrorContext } from '../../../context';
import { updateCalcAnswer } from '../../../api/rest/list';

import Form from '../../../components/form/Form';

import styles from './index.module.scss';
import CustomCo2Item from './components/CustomCo2Item/CustomCo2Item';
import QuestionSharing from '../../../components/questionSharing/QuestionSharing';

import { CheckedIcon, InfoIcon, HelpIcon, FileAddIcon, AddIcon } from 'tt-ui-kit';
import { CALC_MODELS } from '../../../constants';
import FileUploader from '../../../components/fileUploader/FileUploader';

const tooltipText = `Please add the components used in this project.
For each component, specify the amount you need.
Select supplier for a component from the list or add them manually. Invite the supplier to the platform to confirm their participation in your project. This will provide greater transparency for your project.

Carbon Footprint (Optional):
If you have information about a component's carbon footprint (environmental impact), you can provide it here.

If you're unsure about any details, feel free to invite colleagues or teammates to collaborate by clicking "Invite to answer."
`;

const CustomCo2 = ({ nextRoute, setNextRoute, setChartPartData }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { idx } = useParams();
  const [isItemsComponents, SetIsItemsComponents] = useState(false);
  const [scopeIndex, setScopeIndex] = useState(null);

  const { draftId } = useContext(DraftContext);
  const { customCO2Answers, setAnswersData, calcSteps, chartData } = useContext(CalculatorsContext);

  const initNewActivity = () => ({
    id: 0,
    item: null,
    quantity: null,
    supplier: null,
    footprint: null,
    isOffset: false,
  });

  const activities = [...(customCO2Answers?.answers?.[scopeIndex] ?? [])];
  const itemComponents = [...(customCO2Answers?.items ?? [])];
  const suppliers = [...(customCO2Answers?.suppliers ?? [])];

  const onSaveAnswer = async () => {
    const payload = {
      calculator_id: draftId,
      model: CALC_MODELS.CUSTOM_CO2,
      data: JSON.stringify({
        items: itemComponents,
        suppliers: suppliers,
        answers: { ...getFilledAnswers() },
      }),
      last_position: pathname,
      state_graphics: JSON.stringify(chartData),
    };
    await updateCalcAnswer(payload);
  };

  const changeActivity = (value, fieldName, indx) => {
    console.log('changeActivity', value, fieldName, indx);
    const newActivities = activities.map((activity, index) => {
      if (index === indx) {
        return {
          ...activity,
          [fieldName]: value ?? null,
          filled: Object.keys(activity).every((key) => activity[key] !== null),
        };
      }
      return activity;
    });
    if (newActivities[indx]?.filled) onSaveAnswer();
    return newActivities;
  };

  const changeCustomCo2Data = (value, fieldName, indx, isNew = false) => {
    const newSuppliers =
      isNew && fieldName == 'supplier'
        ? [...(customCO2Answers?.suppliers ?? []), value]
        : customCO2Answers.suppliers;
    const newItems =
      isNew && fieldName == 'item'
        ? [...(customCO2Answers?.items ?? []), value]
        : customCO2Answers.items;
    const newActivities = changeActivity(value, fieldName, indx);

    setAnswersData({
      [CALC_MODELS.CUSTOM_CO2]: {
        ...customCO2Answers,
        suppliers: newSuppliers,
        items: newItems,
        answers: {
          ...customCO2Answers?.answers,
          [scopeIndex]: newActivities,
        },
      },
    });
  };

  const changeAnswers = (newActivities) => {
    setAnswersData({
      [CALC_MODELS.CUSTOM_CO2]: {
        ...customCO2Answers,
        answers: {
          ...customCO2Answers?.answers,
          [scopeIndex]: newActivities,
        },
      },
    });
  };

  useEffect(() => {
    if (!customCO2Answers || scopeIndex == null) return;
    if (!customCO2Answers?.answers?.[scopeIndex]?.length) changeAnswers([initNewActivity()]);
  }, [customCO2Answers, scopeIndex]);

  useEffect(() => {
    if (!idx) return;
    const newScopeIndex = calcSteps[idx - 1]?.scopeIndx;
    setScopeIndex(newScopeIndex);
    SetIsItemsComponents(true);
  }, [idx]);

  useEffect(() => {
    //chartData
    if (!chartData?.length || scopeIndex == null) return;
    const questionChartData = chartData[scopeIndex].children.find(
      (item) => item.type == CALC_MODELS.CUSTOM_CO2
    );
    setChartPartData([questionChartData]);
  }, [chartData, scopeIndex]);

  const getFilledAnswers = () => {
    const filledActivities = activities
      .filter(
        (activity) =>
          activity.item?.id && activity.supplier?.id && activity.quantity && activity.footprint
      )
      ?.map((activity) => ({
        ...activity,
        item: activity.item,
        supplier: activity.supplier,
      }));
    const answers = {
      ...customCO2Answers?.answers,
      [scopeIndex]: filledActivities,
    };
    return answers;
  };

  const onAddClick = () => changeAnswers([...activities, initNewActivity()]);

  return (
    <>
      <div className={styles.formContainer}>
        <Form
          headerContent={
            <div className={styles.headerWrapper}>
              <div className={clsx(styles.headerContainer, styles.companyHeaderContainer)}>
                <p>Items/Components</p>
                <span>
                  <Tooltip tooltipText={tooltipText} enterTouchDelay={0}>
                    <InfoIcon className={clsx(styles.infoIcon, styles.topIcon)} />
                  </Tooltip>
                </span>
              </div>
              {/* <div className={styles.inviteBlock}>
                <QuestionSharing
                  calculatorId={draftId}
                  categoryCode={scopeIndex}
                  questionId={scopeIndex}
                />
                <HelpIcon className={styles.helpIcon} />
              </div> */}
            </div>
          }
        >
          {activities &&
            activities.map((activity, indx) => (
              <CustomCo2Item
                index={indx}
                onChange={(value, fieldName, isNew = false) =>
                  changeCustomCo2Data(value, fieldName, indx, isNew)
                }
                activity={activity}
                suppliers={suppliers}
                itemComponents={itemComponents}
              />
            ))}
          <div className={styles.addBlock}>
            {scopeIndex >= 0 && (
              <FileUploader questionCode={`scope${scopeIndex}`} model="customCO2" />
            )}
            <div>
              <Button variant="outlined" className={styles.addButton} onClick={onAddClick}>
                <AddIcon className={styles.addIcon} />
                Add component
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CustomCo2;

import Cookies from 'js-cookie';
import {
  CompanyQuestionsEnabledFields,
  acceptedExtentions,
  CalculatorsListData,
} from './constants';

export const isDraftFilled = (draft) =>
  !!draft.address1 &&
  !!draft.companyId &&
  !!draft.companyName &&
  !!draft.countryId &&
  !!draft.zipCode &&
  !!draft.establishedDate &&
  !!draft.id &&
  ((draft.type !== 'global' && !!draft.industrialGroupId) ||
    (draft.type === 'global' && !!draft.subIndustryId)) &&
  !!draft.legalTypeId &&
  !!draft.name &&
  !!draft.periodReportDate &&
  (!CalculatorsListData[draft.type].requiredFields.numberFields ||
    CalculatorsListData[draft.type].requiredFields.numberFields.every((f) => !!draft[f])) &&
  (!CalculatorsListData[draft.type].requiredFields.stringFields ||
    CalculatorsListData[draft.type].requiredFields.stringFields.every((f) => !!draft[f]));

// !!draft.annualProductivityEachPosition &&
// !!draft.netProfit &&
// !!draft.yearTaxes &&
// !!draft.annualTurnover &&
// !!draft.numberUniqueProducts &&
// !!draft.employeesNumber &&

export const initCompanyAnswer = (code) => {
  const answer = {};
  CompanyQuestionsEnabledFields[code].forEach((ckey) => {
    const key = ckey.split('_')[0];
    answer[key] = '';
  });
  answer.scopeId = '';
  answer.data = '';
  answer.info = '';
  answer.filled = false;
  answer.isNotAvailable = false;
  return answer;
};

export const initCompanyAnswers = () => {
  const answers = {};
  Object.keys(CompanyQuestionsEnabledFields).forEach((code) => {
    answers[code] = [];
    answers[code].push(initCompanyAnswer(code));
  });
  return answers;
};

/* eslint-disable import/prefer-default-export */
export const formatDate = (date) => {
  if (date === null || date === undefined) return null;
  if (typeof date === 'number' && date === parseInt(date, 10)) {
    return `${date}-01-01 00:00:00`;
  }
  const newDate = new Date(date);
  if (!Number.isNaN(newDate.getTime())) {
    const isoDate = newDate.toISOString().split('T')[0];
    return `${isoDate} 00:00:00`;
  }
  return null;
};

/* eslint-disable import/prefer-default-export */
export const formatDateTime = (date) => {
  if (!(date instanceof Date)) return '';

  const isoDate = date.toISOString().split('T')[0];
  const isoTime = date.toISOString().split('T')[1].split('.')[0];
  return `${isoDate} ${isoTime}`;
};

export const validateFile = (ext, size, calcName) => {
  const exts = acceptedExtentions[calcName] || acceptedExtentions.COMMON;
  return (
    ((exts.acceptedImages && exts.acceptedImages.includes(ext)) ||
      (exts.acceptedDocs && exts.acceptedDocs.includes(ext))) &&
    size < 1e8
  );
};

export const getFileType = (ext, calcName) => {
  const exts = acceptedExtentions[calcName] || acceptedExtentions.COMMON;
  if (exts.acceptedImages && exts.acceptedImages.includes(ext)) return 'image';
  if (exts.acceptedDocs && exts.acceptedDocs.includes(ext)) return 'doc';
  return '';
};

export const parseErrors = (response) => {
  const list = {};
  if (response?.errors?.graphQLErrors[0]?.extensions?.validation) {
    Object.keys(response.errors.graphQLErrors[0].extensions.validation).forEach((item, index) => {
      list[item.replace('input.', '')] = {
        message: response.errors.graphQLErrors[0].extensions.validation[item],
      };
    });
  }
  return list;
};

/**
 * рекурсивная функция для изменения свойств обьектов в camelCase
 * @param {*} obj - обект со свойствами в виде snake_case
 * @returns - обект со свойствами в виде camelCase
 */
export const snakeToCamel = (obj) => {
  const newObj = Object.keys(obj).reduce(
    (res, prop) => {
      const newPropName = prop.replaceAll(
        /._./g,
        (match) => `${match.split('_')[0]}${match.split('_')[1].toUpperCase()}`
      );
      const newPropValue =
        obj[prop] && typeof obj[prop] === 'object' ? snakeToCamel(obj[prop]) : obj[prop];
      return Array.isArray(obj) ? [...res, newPropValue] : { ...res, [newPropName]: newPropValue };
    },
    Array.isArray(obj) ? [] : {}
  );
  return newObj;
};

export const openAppLoader = () => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = 1;
    loader.style.visibility = 'visible';
  }
};

export const closeAppLoader = () => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = 0;
    loader.style.visibility = 'hidden';
  }
};

export const logoutUserHandler = (logoutApiMethod, disconnectSocketMethod, withRedirect = true) => {
  openAppLoader();

  logoutApiMethod().finally(() => {
    closeAppLoader();
    const domainValue = process.env.REACT_APP_DOMAIN;
    Cookies.remove('access_token', { domain: domainValue });
    Cookies.remove('refresh_token', { domain: domainValue });
    Cookies.remove('userId', { domain: domainValue });
    Cookies.remove('companyId', { domain: domainValue });
    Cookies.remove('calculatorId', { domain: domainValue });
    Cookies.remove('last_active', {
      domain: domainValue,
    });
    localStorage.removeItem('currentUserData');
    localStorage.removeItem('currentCompanyData');

    if (disconnectSocketMethod) {
      disconnectSocketMethod();
    }

    if (withRedirect) {
      window.location.href = '/dashboard/score_and_reporting/menu/calculators/drafts';
    } else {
      window.location.reload();
    }
  });
};

const isJWTTokenValid = (token) => {
  const tokenParts = token.split('.');

  if (tokenParts.length === 3) {
    try {
      const payload = JSON.parse(atob(tokenParts[1]));
      const expTime = payload.exp * 1000 - 60000;

      if (expTime && Date.now() <= expTime) {
        return true;
      }
    } catch (err) {
      console.error('Error decoding JWT token:', err);
      return false;
    }
  }

  return false;
};

export const refreshAuthToken = (refreshHandler) => {
  const domainValue = process.env.REACT_APP_DOMAIN;
  const accessToken = Cookies.get('access_token', { domain: domainValue });
  const refreshToken = Cookies.get('refresh_token', { domain: domainValue });

  const logout = () => {
    Cookies.remove('access_token', { domain: domainValue });
    Cookies.remove('refresh_token', { domain: domainValue });
    Cookies.remove('userId', { domain: domainValue });
    Cookies.remove('marketId', { domain: domainValue });
    Cookies.remove('companyId', { domain: domainValue });
    Cookies.remove('calculatorId', { domain: domainValue });
    Cookies.remove('last_active', {
      domain: domainValue,
    });

    localStorage.removeItem('currentUserData');
    localStorage.removeItem('currentCompanyData');
    window.location.href = '/dashboard/feed';
  };

  if (!accessToken || !refreshToken) {
    logout();
    return;
  }

  const isAccessTokenValid = isJWTTokenValid(accessToken);

  if (isAccessTokenValid) {
    return;
  }

  refreshHandler({
    variables: {
      input: {
        refresh_token: refreshToken,
      },
    },
  })
    .then((res) => {
      const newAccessToken = res?.data?.refreshToken?.access_token;
      const newRefreshToken = res?.data?.refreshToken?.refresh_token;

      if (newAccessToken && newRefreshToken) {
        Cookies.set('access_token', newAccessToken, { domain: domainValue });
        Cookies.set('refresh_token', newRefreshToken, { domain: domainValue });
        return;
      }

      logout();
    })
    .catch((err) => {
      logout();
    });
};

export const getCarbonLabel = (field) => {
  const labels = {
    f1: 'Activity',
    f2: 'Type',
    f3: 'Size',
    f4: 'Additional',
    f5: 'Additional',
    data: 'Data',
  };
  return labels[field] ?? '';
};

export const getCustomCO2Label = (field) => {
  const labels = {
    footprint: 'Footprint',
    quantity: 'Quantity',
    item: 'Item',
    supplier: 'Supplier',
  };
  return labels[field] ?? '';
};

import React from 'react';
import styles from './UserBlockSimple.module.scss';
import { Avatar } from '@mui/material';

const UserBlockSimple = ({ user }) => (
  <div className={styles.avatarRow}>
    <div>
      <Avatar src={user?.avatar} className={styles.avatar} />
    </div>
    <div className={styles.fio}>
      <p>
        {user?.lastName} {user?.firstName}
      </p>
    </div>
  </div>
);

export default UserBlockSimple;

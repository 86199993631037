import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CalculatorsContext, ErrorContext, DraftContext } from '../../context';
import { formatDate } from '../../utils';
import { createNewDraft } from '../../api/rest/list';

const companyValidateList = {
  companyName: 'Company name',
  establishedDate: 'Establish date',
  address1: 'Address',
  countryId: 'Country',
  zipCode: 'Zip/Postal code',
  industrialGroupId: 'Industry group',
  subIndustryId: 'Prime subindustry',
  legalTypeId: 'Company legal type',
};

const NewAssessment = () => {
  const navigate = useNavigate();
  const { calcName, companyId } = useParams();
  const { calculatorsList, createCalculatorList, config } = useContext(CalculatorsContext);
  const { changeUserData, companies, user, setDraftId, draftId, setCompanyId } =
    useContext(DraftContext);
  const { setErrorAlert } = useContext(ErrorContext);

  useEffect(() => {
    window.sessionStorage.removeItem('SELECTED_DRAFT_ID');
    setDraftId(null);
  }, []);

  const onErrorMessageClose = (uri) => {
    navigate('/error', { replace: true });
  };

  const onNewDraft = () => {
    try {
      const now = new Date().toISOString().split('.')[0].replace('T', ' ');
      createNewDraft(
        {
          company_id: companyId,
          company_name: companies[companyId].companyName,
          email: user.email,
          legal_type_id: companies[companyId].legalTypeId,
          industrial_group_id: companies[companyId].industrialGroupId,
          sub_industry_id: companies[companyId].subIndustryId,
          company_date: companies[companyId].establishedDate
            ? formatDate(companies[companyId].establishedDate)
            : now,
          registration_country_id: companies[companyId].countryId,
          zip_code: companies[companyId].zipCode,
          address1: companies[companyId].address1,
          type: calcName,
          language: 'en',
        },
        { companyId: companyId }
      ).then((res) => {
        const { data, success, message, statusCode } = res.data;
        if (success) {
          setDraftId(data.id);
          setCompanyId(companyId);
        } else {
          setErrorAlert(message, onErrorMessageClose);
        }
      });
    } catch (err) {
      console.log(err);
      // TODO: показывать ошибку, если что-то пошло не так
      // TODO: при создании и сохранении дизейблить кнопку и показывать в ней спиннер
    }
  };

  const getCompanyWarning = (c) => {
    const warn = Object.keys(companyValidateList).reduce((res, key) => {
      if (!c[key] && !c.addressLocation[key]) res.push(companyValidateList[key]);
      return res;
    }, []);
    return warn;
  };

  const getCompanyWarningText = (c) =>
    `The company does not have information about such parameters: ${getCompanyWarning(c).join(', ')}`;

  useEffect(() => {
    if (!calculatorsList || !draftId || !config) return;
    navigate('/start', { replace: true });
  }, [calculatorsList, draftId, config]);

  useEffect(() => {
    const company = companies[companyId];
    if (!company) {
      setErrorAlert('The company is not in the list available to the user.', onErrorMessageClose);
    } else {
      const isCompanyFilled = (c) =>
        c.userId === user.id &&
        !!c.address1 &&
        !!c.id &&
        !!c.companyName &&
        !!c.countryId &&
        !!c.establishedDate &&
        !!c.zipCode &&
        ((calcName !== 'global' && !!c.industrialGroupId) ||
          (calcName === 'global' && !!c.subIndustryId));
      if (company && isCompanyFilled(company)) {
        onNewDraft();
        changeUserData({ ...user, companyId });
      } else {
        setErrorAlert(
          `The company details are not filled. ${getCompanyWarningText(company)}`,
          onErrorMessageClose
        );
      }
    }
  }, [companies]);

  return <></>;
};

export default NewAssessment;

import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { openNotification } from 'tt-ui-lib/core';
import styles from './SignUpModal.module.scss';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Button } from 'tt-ui-kit';
import { TextField, Checkbox, FormControlLabel } from '@mui/material';
import CheckBoxOutlined from '@mui/icons-material/CheckBoxOutlined';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_AGREEMENT, CREATE_NEW_DIGITAL_INDIVIDUAL } from '../../../api/apollo/api';
import { PolicyModalWrapper } from '../../../modules/tt-modals/modals/PolicyModal';

const SignUpModal = ({ setMode, setOpen }) => {
  const whitespacesReg = /^\s*\S.*$/;
  const specialCharReg = /^[^<>'"/;`%]*$/;
  const schema = yup.object().shape({
    first_name: yup
      .string()
      .required('Please fill out the rest fields. This is mandatory.')
      .max(35, 'Too many characters in first name.')
      .matches(whitespacesReg, 'The field cannot contain only spaces.')
      .matches(specialCharReg, 'Special characters are prohibited.'),
    last_name: yup
      .string()
      .required('Please fill out the rest fields. This is mandatory.')
      .max(35, 'Too many characters in last name.')
      .matches(whitespacesReg, 'The field cannot contain only spaces.')
      .matches(specialCharReg, 'Special characters are prohibited.'),
    email: yup
      .string()
      .required('Please fill out the rest fields. This is mandatory.')
      .email('Email is invalid'),
    password: yup
      .string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/(?=.*\d)/, 'Include at least one number')
      .matches(/(?=.*[!@#$%^&*])/, 'Include at least 1 special character (!@#$%^&*)')
      .matches(/(?=.*[a-z])/, 'Include at least 1 lowercase letter')
      .matches(/(?=.*[A-Z])/, 'Include at least 1 uppercase letter')
      .required('Please fill out the rest fields. This is mandatory.'),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Confirmation doesn`t match your password')
      .required('Please fill out the rest fields. This is mandatory.'),
  });

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [isHonorAgree, setIsHonorAgree] = useState(false);
  const [isHonorModalOpen, setIsHonorModalOpen] = useState(false);
  const [isPolicyAgree, setIsPolicyAgree] = useState(false);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);
  const [isPolicyLoading, setIsPolicyLoading] = useState(false);
  const [policyText, setPolicyText] = useState(null);
  const [honorText, setHonorText] = useState(null);
  const [getAgreement] = useLazyQuery(GET_AGREEMENT, {});

  const [createNewDigitalIndividual] = useMutation(CREATE_NEW_DIGITAL_INDIVIDUAL);

  const signUpHandler = async (data) => {
    if (isHonorAgree && isPolicyAgree) {
      try {
        const newUser = await createNewDigitalIndividual({
          variables: { input: data },
        });
        if (newUser) {
          setOpen(false);
        }
      } catch (e) {
        if (e?.message === 'Validation failed for the field [registerIndividual].') {
          openNotification({
            message: 'This email already taken, plaease choose another email.',
            type: 'error',
          });
        }
      }
    } else {
      openNotification({
        message: 'Please, accept Privacy Policy and Honor Code.',
        type: 'error',
      });
    }
  };

  const getAgreementData = async () => {
    setIsPolicyLoading(true);

    const res = await getAgreement();
    if (res?.data) {
      const policy = res.data.agreementPrivacyAndHonor.find(
        (agreement) => agreement.field === 'Privacy Policy'
      );

      setPolicyText(policy.body);

      const honor = res.data.agreementPrivacyAndHonor.find(
        (agreement) => agreement.field === 'Honor Code'
      );

      setHonorText(honor.body);
    }
    setIsPolicyLoading(false);
  };

  const handleModalClose = (setIsOpen) => setIsOpen(false);

  return (
    <div className={styles.signUpWrapper}>
      <div className={styles.subtitleSignUp}>
        <p>
          Thank you for your support.
          <br />
          Since all invitations are transparent on TransparenTerra, please complete this short
          sign-up form before forwarding this email to your colleague. We appreciate your
          cooperation.
        </p>
      </div>
      <form className={styles.signUpForm} onSubmit={handleSubmit(signUpHandler)}>
        <div className={styles.inputRow}>
          <Controller
            name="first_name"
            control={control}
            defaultValue=""
            rules={{ required: 'First name is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="First name"
                variant="outlined"
                error={!!errors.first_name}
                helperText={errors.first_name?.message}
              />
            )}
          />
          <Controller
            name="last_name"
            control={control}
            defaultValue=""
            rules={{ required: 'Last name is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Last name"
                variant="outlined"
                error={!!errors.last_name}
                helperText={errors.last_name?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: 'Email is required',
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Invalid email format',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Email"
                type="email"
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
        </div>
        <div className={styles.inputRow}>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: 'Password is required',
              minLength: {
                value: 6,
                message: 'Password must be at least 6 characters',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Password"
                type="password"
                variant="outlined"
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            )}
          />
          <Controller
            name="password_confirmation"
            control={control}
            defaultValue=""
            rules={{
              required: 'Confirm password is required',
              validate: (value) => value === getValues('password') || 'Passwords do not match',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Confirm password"
                type="password"
                variant="outlined"
                error={!!errors.password_confirmation}
                helperText={errors.password_confirmation?.message}
              />
            )}
          />
        </div>
        <div className={styles.checkBoxBlock}>
          <FormControlLabel
            control={
              <Checkbox
                checkedIcon={<CheckBoxOutlined />}
                className={styles.checkboxControl}
                checked={isPolicyAgree}
                onChange={async () => {
                  if (isPolicyAgree) {
                    setIsPolicyAgree(false);
                  } else {
                    getAgreementData().then();
                    setIsPolicyModalOpen(true);
                  }
                }}
              />
            }
            label="Privacy Policy"
            classes={{ label: styles.label }}
            sx={{ marginLeft: 0 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checkedIcon={<CheckBoxOutlined />}
                className={styles.checkboxControl}
                checked={isHonorAgree}
                onChange={async () => {
                  if (isHonorAgree) {
                    setIsHonorAgree(false);
                  } else {
                    getAgreementData().then();
                    setIsHonorModalOpen(true);
                  }
                }}
              />
            }
            label="Honor Code"
            classes={{ label: styles.label }}
            sx={{ marginLeft: 0 }}
          />
        </div>
        <div className={styles.signUpAction}>
          <div className={styles.formLink} onClick={() => setMode('in')}>
            Already have an account? Sign in
          </div>
          <Button type="primary" isSubmit>
            Sign Up
          </Button>
        </div>
      </form>
      <PolicyModalWrapper
        isModelOpen={isPolicyModalOpen}
        setIsOpen={setIsPolicyModalOpen}
        handleClose={handleModalClose}
        setIsAgree={setIsPolicyAgree}
        isAgree={isPolicyAgree}
        text={policyText}
        checkboxText="I agree to the Privacy Policy"
        loading={isPolicyLoading}
      />
      <PolicyModalWrapper
        isModelOpen={isHonorModalOpen}
        setIsOpen={setIsHonorModalOpen}
        handleClose={handleModalClose}
        setIsAgree={setIsHonorAgree}
        isAgree={isHonorAgree}
        text={honorText}
        checkboxText="I accept the Honor Code of TransparenTerra members"
        loading={isPolicyLoading}
      />
    </div>
  );
};

export default SignUpModal;

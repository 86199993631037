import { gql } from '@apollo/client';
import { TOKEN } from './fragments';

const GET_AGREEMENT = gql`
  query @api(name: sso) {
    agreementPrivacyAndHonor {
      id
      field
      body
    }
  }
`;

export const GET_PRO_LOGIN = gql`
  mutation ($input: LoginInput) @api(name: sso) {
    login(input: $input) {
      access_token
      refresh_token
      expires_in
      token_type
      user {
        id
        email
        market_id
        calculator_id
        firstName: first_name
        lastName: last_name
      }
    }
  }
`;

const CREATE_NEW_DIGITAL_INDIVIDUAL = gql`
  ${TOKEN}
  mutation CreateNewDigitalIndividual($input: RegisterIndividualInput) @api(name: sso) {
    registerIndividual(input: $input) {
      tokens {
        ...TokenFields
        user {
          id
          email
          first_name
          last_name
          market_id
          calculator_id
        }
      }
      status
    }
  }
`;

const DELETE_ASSESSMENT = gql`
  mutation @api(name: calculator) {
    co2Activity {
      id
      name
    }
  }
`;

const SEND_ASSESSMENT = gql`
  query @api(name: calculator) {
    co2Activity {
      id
      name
    }
  }
`;

const GET_PRODUCT_MATERIALS_DATA = gql`
  query @api(name: calculator) {
    getCo2ProductQuestions {
      id
      scopeId: scope_id
      name
      types {
        id
        name
        units {
          id
          name
          additional {
            co2e_primary
            co2e_reused
            co2e_open_loop
            co2e_closed_loop
            co2e_combustion
            co2e_composting
            co2e_landfill
            co2e_anaerobic
            co2e_laden_average
            co2e_laden_100
            co2e_laden_50
            co2e_laden_0
            co2e_lpg
            co2e_cng
            co2e_without_rf
            co2e_with_rf
            co2e_battery
            co2e_hybrid
            co2e_plugin
            co2e_unknown
            co2e_petrol
            co2e_diesel
          }
        }
      }
    }
  }
`;

const GET_PACKAGE_MATERIALS_DATA = gql`
  query @api(name: calculator) {
    getCo2PackageQuestions {
      id
      scopeId: scope_id
      name
      types {
        id
        name
        units {
          id
          name
          additional {
            co2e_primary
            co2e_reused
            co2e_open_loop
            co2e_closed_loop
            co2e_combustion
            co2e_composting
            co2e_landfill
            co2e_anaerobic
            co2e_laden_average
            co2e_laden_100
            co2e_laden_50
            co2e_laden_0
            co2e_lpg
            co2e_cng
            co2e_without_rf
            co2e_with_rf
            co2e_battery
            co2e_hybrid
            co2e_plugin
            co2e_unknown
            co2e_petrol
            co2e_diesel
          }
        }
      }
    }
  }
`;

const GET_COMPANY_QUESTIONS_DATA = gql`
  query @api(name: calculator) {
    getCo2CompanyQuestions {
      scopeNum
      scope {
        name
        code
        activities {
          id
          scopeId: scope_id
          name
          types {
            id
            name
            units {
              id
              name
              additional {
                co2e_primary
                co2e_reused
                co2e_open_loop
                co2e_closed_loop
                co2e_combustion
                co2e_composting
                co2e_landfill
                co2e_anaerobic
                co2e_laden_average
                co2e_laden_100
                co2e_laden_50
                co2e_laden_0
                co2e_lpg
                co2e_cng
                co2e_without_rf
                co2e_with_rf
                co2e_battery
                co2e_hybrid
                co2e_plugin
                co2e_unknown
                co2e_petrol
                co2e_diesel
              }
              size {
                id
                name
                additional {
                  co2e_primary
                  co2e_reused
                  co2e_open_loop
                  co2e_closed_loop
                  co2e_combustion
                  co2e_composting
                  co2e_landfill
                  co2e_anaerobic
                  co2e_laden_average
                  co2e_laden_100
                  co2e_laden_50
                  co2e_laden_0
                  co2e_lpg
                  co2e_cng
                  co2e_without_rf
                  co2e_with_rf
                  co2e_battery
                  co2e_hybrid
                  co2e_plugin
                  co2e_unknown
                  co2e_petrol
                  co2e_diesel
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GET_CALCULATOR_QUESTIONS = gql`
  query @api(name: calculator) {
    questions {
      id
      name
      esg
      esgPosition: position_in_esg
      questions {
        type
        code
        title: question_title
        body: question_body
      }
    }
  }
`;

const GET_GLOBAL_QUESTIONS_DATA = gql`
  query @api(name: calculator) {
    getGlobalQuestions {
      GlobalQuestions
    }
  }
`;

const GET_GLOBAL_INPUT_TYPES_DATA = gql`
  query ($input: String) @api(name: calculator) {
    getGlobalVariants(sub_industrial_id: $input) {
      GlobalVariants
    }
  }
`;

const GET_COUNTRIES = gql`
  query @api(name: market) {
    showCountries {
      id
      name
    }
  }
`;

const GET_INDUSTIAL_GROUPS = gql`
  query @api(name: market) {
    showIndustryGroups {
      id
      name
      code
    }
  }
`;

const GET_SUBINDUSTRIES = gql`
  query @api(name: market) {
    showSubIndustries {
      id
      name
      code
    }
  }
`;

const GET_LEGAL_TYPES = gql`
  query @api(name: market) {
    showCompanyLegalTypes {
      id
      name
    }
  }
`;

const CAN_PAY_DRAFT = gql`
  query ($input: CalculatorPayAccessInput!) @api(name: market) {
    getCalculatorsPayAccess(input: $input) {
      id
      canPay
      paymentData {
        id
        exact_id
        payment_status
        status
      }
    }
  }
`;

const GET_USER = gql`
  query ($id: ID!) @api(name: sso) {
    user(id: $id) {
      id
      email
      firstName: first_name
      lastName: last_name
      avatar
      roleName: role_name
    }
  }
`;

const GET_COMPANIES = gql`
  query ($id: String!) @api(name: market) {
    showAllCompaniesByUserId(user_id: $id) {
      id
      userId: user_id
      companyName: company_name
      email
      industrialGroupId: industry_group_id
      subIndustryId: sub_industry_id
      legalTypeId: legal_type_id
      establishedDate: established_date
      addressLocation {
        countryId: country_id
        zipCode: postcode
        address
      }
      address2
    }
  }
`;

const GET_OWN_DRAFTS = gql`
  query @api(name: calculator) {
    getOwnDrafts {
      id
      name
      userId: user_id
      countryId: registration_country_id
      companyId: company_id
      companyName: company_name
      establishedDate: company_date
      legalTypeId: legal_type_id
      address1
      address2
      zipCode: zip_code
      industrialGroupId: industrial_group_id
      subIndustryId: sub_industry_id
      annualTurnover: annual_turnover
      netProfit: net_profit
      yearTaxes: year_taxes
      numberUniqueProducts: number_unique_products
      annualProductivityEachPosition: annual_productivity_each_position
      employeesNumber: employees_number
      periodReportDate: period_report_date
      periodReportDateEnd: period_report_date_end
      createdAt: created_at
      updatedAt: updated_at
      type
      answers
      companyAnswers: company_answers
      globalAnswers: global_answers
      lastPosition: last_position
      completedAt: completed_at
      stateGraphics: state_graphics
      ukGhgCollectionId: factor_id
    }
  }
`;

const GET_DRAFTS_BY_COMPANY = gql`
  query ($id: String!, $type: String) @api(name: calculator) {
    draftsByCompanyAndType(company_id: $id, type: $type) {
      id
      name
      userId: user_id
      countryId: registration_country_id
      companyId: company_id
      companyName: company_name
      establishedDate: company_date
      legalTypeId: legal_type_id
      address1
      address2
      zipCode: zip_code
      industrialGroupId: industrial_group_id
      subIndustryId: sub_industry_id
      annualTurnover: annual_turnover
      netProfit: net_profit
      yearTaxes: year_taxes
      numberUniqueProducts: number_unique_products
      annualProductivityEachPosition: annual_productivity_each_position
      employeesNumber: employees_number
      periodReportDate: period_report_date
      periodReportDateEnd: period_report_date_end
      createdAt: created_at
      updatedAt: updated_at
      type
      answers
      companyAnswers: company_answers
      globalAnswers: global_answers
      lastPosition: last_position
      completedAt: completed_at
      stateGraphics: state_graphics
      ukGhgCollectionId: factor_id
    }
  }
`;

const GET_DRAFT_BY_ID = gql`
  query ($id: ID!) @api(name: calculator) {
    draftById(id: $id) {
      id
      name
      userId: user_id
      countryId: registration_country_id
      companyId: company_id
      companyName: company_name
      establishedDate: company_date
      legalTypeId: legal_type_id
      address1
      address2
      zipCode: zip_code
      industrialGroupId: industrial_group_id
      subIndustryId: sub_industry_id
      annualTurnover: annual_turnover
      netProfit: net_profit
      yearTaxes: year_taxes
      numberUniqueProducts: number_unique_products
      annualProductivityEachPosition: annual_productivity_each_position
      employeesNumber: employees_number
      periodReportDate: period_report_date
      periodReportDateEnd: period_report_date_end
      createdAt: created_at
      updatedAt: updated_at
      type
      answers
      companyAnswers: company_answers
      globalAnswers: global_answers
      lastPosition: last_position
      completedAt: completed_at
      stateGraphics: state_graphics
      ukGhgCollectionId: factor_id
    }
  }
`;

const CREATE_DRAFT = gql`
  mutation ($input: EsgLocalDraftInput!) @api(name: calculator) {
    createEsgDraft(input: $input) {
      id
    }
  }
`;

const CREATE_INACTIVE_DRAFT = gql`
  mutation ($input: EsgLocalDraftInput) @api(name: calculator) {
    createOrGetInactiveEsgDraft(input: $input) {
      id
      userId: user_id
      companyId: company_id
      companyName: company_name
      email
      legalTypeId: legal_type_id
      industrialGroupId: industrial_group_id
      subIndustryId: sub_industry_id
      establishedDate: company_date
      countryId: registration_country_id
      zipCode: zip_code
      address1
      address2
      annualTurnover: annual_turnover
      netProfit: net_profit
      yearTaxes: year_taxes
      numberUniqueProducts: number_unique_products
      annualProductivityEachPosition: annual_productivity_each_position
      employeesNumber: employees_number
      periodReportDate: period_report_date
      periodReportDateEnd: period_report_date_end
      type
      name
      answers
      companyAnswers: company_answers
      packageAnswers: package_answers
      productAnswers: product_answers
      globalAnswers: global_answers
      completedAt: completed_at
      stateGraphics: state_graphics
      lastPosition: last_position
      ukGhgCollectionId: factor_id
    }
  }
`;

const REPLACE_DRAFT = gql`
  mutation ($input: ReplaceDraftInput, $old_id: ID!) @api(name: calculator) {
    replaceEsgDraft(input: $input, old_id: $old_id) {
      id
      userId: user_id
      companyId: company_id
      companyName: company_name
      email
      legalTypeId: legal_type_id
      industrialGroupId: industrial_group_id
      subIndustryId: sub_industry_id
      establishedDate: company_date
      countryId: registration_country_id
      zipCode: zip_code
      address1
      address2
      annualTurnover: annual_turnover
      netProfit: net_profit
      yearTaxes: year_taxes
      numberUniqueProducts: number_unique_products
      annualProductivityEachPosition: annual_productivity_each_position
      employeesNumber: employees_number
      periodReportDate: period_report_date
      periodReportDateEnd: period_report_date_end
      type
      name
      answers
      companyAnswers: company_answers
      packageAnswers: package_answers
      productAnswers: product_answers
      globalAnswers: global_answers
      completedAt: completed_at
      stateGraphics: state_graphics
      lastPosition: last_position
      ukGhgCollectionId: factor_id
    }
  }
`;

const UPDATE_DRAFT = gql`
  mutation ($input: EsgLocalDraftUpdateInput!) @api(name: calculator) {
    updateEsgDraft(input: $input) {
      id
    }
  }
`;

const COMPLETE_DRAFT = gql`
  mutation ($input: SaveTtTestInput) @api(name: calculator) {
    saveEsgTest(input: $input) {
      id
      industrialGroupId: industrial_group_id
      testYear: test_year
      environmental
      social
      governance
      productTotal: co_product_total
      packageTotal: co_package_total
      companyTotal: co_company_total
      globalTotal: global
    }
  }
`;

const DELETE_DRAFT = gql`
  mutation ($id: ID!) @api(name: calculator) {
    deleteEsgDraft(id: $id) {
      id
    }
  }
`;

const RENAME_DRAFT = gql`
  mutation ($input: RenameEsgDraftInput!) @api(name: calculator) {
    renameEsgDraft(input: $input) {
      id
    }
  }
`;

const GET_QUESTIONS_FILE = gql`
  query ($input: getFileInput) @api(name: calculator) {
    getQuestionsFile(input: $input) {
      id
      draftId: calc_tt_draft_id
      questionCode: questions_code
      originalName: original_name
      type
      uuidName: file
      description
      state
    }
  }
`;

const DELETE_QUESTIONS_FILE = gql`
  mutation ($id: ID!) @api(name: calculator) {
    deleteQuestionsFile(id: $id)
  }
`;

const EDIT_QUESTIONS_FILES = gql`
  mutation ($files: [EditFileInput]) @api(name: calculator) {
    editFiles(files: $files) {
      id
      questionCode: questions_code
      draftId: calc_tt_draft_id
      type
      originalName: original_name
      uuidName: file
      description
      state
    }
  }
`;

const GET_FILES_COUNT = gql`
  query ($input: FilesCountInput!) @api(name: calculator) {
    getProductFilesCount(input: $input) {
      answer_id
      type
      count
    }
  }
`;

const GET_URL_FOR_PRIVATE_UPLOAD = gql`
  mutation getPrivateUrl($input: sFile) @api(name: calculator) {
    getUrlForPrivateUpload(input: $input) {
      url
      downloadUrl: download_url
    }
  }
`;

const GET_PRELIMINARY_ASSESSMENT = gql`
  mutation ($id: ID!) @api(name: calculator) {
    preliminaryAssessment(id: $id) {
      id
      subIndustryId: sub_industry_id
      country: registration_country_id
      industrialGroupId: industrial_group_id
      testYear: test_year
      environmental
      social
      governance
      productTotal: co_product_total
      packageTotal: co_package_total
      companyTotal: co_company_total
      globalTotal: global
    }
  }
`;

const ADD_UPLOADED_FILE_TO_QUESTION = gql`
  mutation ($input: UploadedFileInput) @api(name: calculator) {
    addUploadedFileToQuestion(input: $input) {
      id
      questionCode: questions_code
      draftId: calc_tt_draft_id
      type
      originalName: original_name
      uuidName: file
      description
    }
  }
`;

const GET_CURRENT_USER = gql`
  query @api(name: sso) {
    currentUser {
      id
      email
      firstName: first_name
      lastName: last_name
      avatar
      roleName: role_name
    }
  }
`;

const LOGOUT_USER = gql`
  mutation @api(name: sso) {
    logout {
      status
      message
    }
  }
`;

const GET_PRICE = gql`
  query ($input: DraftQuickCostInput!) @api(name: calculator) {
    getQuickCalculatorPrice(input: $input) {
      draftId: draft_id
      baseCost: base_cost
      extraCost: extra_cost
      discount
      finalCost: final_cost
    }
  }
`;

const GET_ROLE = gql`
  query @api(name: sso) {
    roles {
      id
      name
    }
  }
`;

const SEND_INVITE = gql`
  mutation ($input: SendInvitationInput!) @api(name: sso) {
    sendInvitation(input: $input) {
      link
      code
      invitation {
        id
        first_name
        last_name
        tel
        email
        level
        status
        created_at
        created_by
        expired_at
      }
    }
  }
`;
const GET_INVITATIONS_WITH_PAGINATION = gql`
  query ($input: InvitationsSearchInput, $first: Int!, $page: Int) @api(name: sso) {
    invitationsWithPagination(input: $input, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        firstName: first_name
        lastName: last_name
        tel
        email
        level
        status
        created_at
        created_by
        expired_at
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation ($input: RefreshTokenInput) @api(name: sso) {
    refreshToken(input: $input) {
      access_token
      refresh_token
      expires_in
      token_type
    }
  }
`;

export {
  GET_AGREEMENT,
  CAN_PAY_DRAFT,
  CREATE_NEW_DIGITAL_INDIVIDUAL,
  ADD_UPLOADED_FILE_TO_QUESTION,
  GET_PRELIMINARY_ASSESSMENT,
  GET_URL_FOR_PRIVATE_UPLOAD,
  GET_PRODUCT_MATERIALS_DATA,
  GET_PACKAGE_MATERIALS_DATA,
  GET_COMPANY_QUESTIONS_DATA,
  GET_GLOBAL_QUESTIONS_DATA,
  GET_GLOBAL_INPUT_TYPES_DATA,
  GET_CALCULATOR_QUESTIONS,
  GET_COUNTRIES,
  GET_USER,
  GET_COMPANIES,
  GET_INDUSTIAL_GROUPS,
  GET_SUBINDUSTRIES,
  GET_LEGAL_TYPES,
  GET_OWN_DRAFTS,
  GET_DRAFTS_BY_COMPANY,
  DELETE_ASSESSMENT,
  SEND_ASSESSMENT,
  CREATE_DRAFT,
  CREATE_INACTIVE_DRAFT,
  REPLACE_DRAFT,
  UPDATE_DRAFT,
  COMPLETE_DRAFT,
  GET_DRAFT_BY_ID,
  DELETE_DRAFT,
  RENAME_DRAFT,
  GET_QUESTIONS_FILE,
  GET_FILES_COUNT,
  EDIT_QUESTIONS_FILES,
  DELETE_QUESTIONS_FILE,
  GET_CURRENT_USER,
  LOGOUT_USER,
  GET_PRICE,
  GET_ROLE,
  SEND_INVITE,
  GET_INVITATIONS_WITH_PAGINATION,
};

/* eslint-disable */

import PropTypes from 'prop-types';
import React, { useReducer } from 'react';

import {
  SET_CURRENT_CALC_SECTION,
  SET_GLOBAL_INPUT_TYPES,
  SET_CALCULATORS_LIST,
  SET_SCORE,
  SET_LAST_VISITED_INDEX,
  SET_CALCULATOR_NAME,
  SET_CHART_DATA,
  SET_CONFIG,
  SET_ANSWERS_DATA,
  SET_QUESTIONS_MAP,
  SET_CALC_DATA,
  SET_SHARED_ANSWERS_DATA,
} from '../types';
import { DefaultCalculatorPrices } from '../../constants';

import CalculatorsContext from './calculatorsContext';
import calculatorsReducer from './calculatorsReducer';

const CalculatorsState = ({ children }) => {
  const initialState = {
    questions: [],
    calcSteps: [],
    carbonStructure: {
      activities: [],
      types: [],
      units: [],
      classes: [],
    },
    calculatorsList: [],
    carbonSources: {
      product: [],
      package: [],
    },
    company: {},
    fullChartData: [],
    chartData: [],
    lastVisitedIndex: -1,
    // calculatorAnswers: { ...DefaultCalculatorAnswers },
    calculatorAnswers: {},
    companyAnswers: {},
    score: {
      environmental: 0,
      social: 0,
      governance: 0,
      total: 0,
      productTotal: 0,
      packageTotal: 0,
      companyTotal: 0,
      testYear: new Date().getFullYear(),
    },
    calculatorPrices: { ...DefaultCalculatorPrices },
    calculatorCost: 0,
    calculatorName: '',
    chartCreated: false,
    config: null,
    shares: null,
  };

  // const [filesList, setFilesList] = useState([]);
  const [state, dispatch] = useReducer(calculatorsReducer, initialState);

  //----------------------------------------------
  const buildCalcSteps = (carbonData, customCo2NeededScopes) => {
    // carbonData – это то, что приходит из бэка (scopes: [ {categories: [...]}, ... ] ),
    // обьект с вопросами
    // customCo2NeededScopes – массив индексов scope, перед которыми надо вставлять customCO2

    let steps = [];
    let runningIndex = 0;

    carbonData.forEach((scope, sIndex) => {
      // Если мы хотим вставить customCO2 до данного scope:
      if (customCo2NeededScopes.includes(sIndex)) {
        steps.push({
          index: runningIndex++,
          label: `Custom CO2 for scope${sIndex}`,
          model: 'customCO2',
          scopeIndx: sIndex,
          route: `/calculators/carbon/${runningIndex}`,
          id: `customCO2_${sIndex}`,
        });
      }

      scope.categories.forEach((cat, cIndex) => {
        cat.questions.forEach((q, qIndex) => {
          steps.push({
            index: runningIndex++,
            label: `${scope.name} / ${cat.name} / ${q.name}`,
            model: 'carbon',
            scopeIndx: sIndex,
            categoryIndx: cIndex,
            questionIndx: qIndex,
            route: `/calculators/carbon/${runningIndex}`,
            id: q.id,
          });
        });
      });
    });
    return steps;
  };

  const isAnyAnswerExists = () => {
    const { answers } = state;
    let result = false;

    if (answers?.carbon) {
      Object.keys(answers?.carbon).map((cat) => {
        const category = answers?.carbon[cat];
        Object.keys(category).forEach((key) => {
          const activities = category[key];
          activities.forEach((el) => {
            if (el?.filled) {
              result = true;
            }
          });
        });
      });
    }
    if (answers?.customCO2?.answers) {
      Object.keys(answers?.customCO2?.answers).map((key) => {
        const activities = answers?.customCO2?.answers[key];
        activities.forEach((el) => {
          if (el?.filled) {
            result = true;
          }
        });
      });
    }
    return result;
  };

  //----------------------------------------------
  const setChartData = (data) => {
    dispatch({
      type: SET_CHART_DATA,
      payload: { chartData: [...data] },
    });
  };

  const setConfig = (data) => {
    const calcs = data.models.map((m) => m.model);
    dispatch({
      type: SET_CONFIG,
      payload: { ...data },
    });
    dispatch({
      type: SET_CALCULATORS_LIST,
      payload: [...calcs],
    });
    dispatch({
      type: SET_CALCULATOR_NAME,
      payload: data.code,
    });
  };

  const setCurrentCalcSection = (data) => {
    dispatch({
      type: SET_CURRENT_CALC_SECTION,
      payload: data,
    });
  };

  const changeGlobalInputTypesData = (data) => {
    const { GlobalVariants } = data;
    dispatch({
      type: SET_GLOBAL_INPUT_TYPES,
      payload: {
        globalInputTypes: JSON.parse(GlobalVariants),
      },
    });
  };

  const setQuestionsData = (questions) => {
    const calcSteps = buildCalcSteps(questions.carbon, [0, 1]);
    dispatch({
      type: SET_CALC_DATA,
      payload: {
        calcSteps,
        questions,
      },
    });
  };

  const setQuestionsMap = (map) => {
    dispatch({
      type: SET_QUESTIONS_MAP,
      payload: map,
    });
  };
  const setAnswersData = (answers) => {
    console.log('setAnswersData', answers);
    dispatch({
      type: SET_ANSWERS_DATA,
      payload: answers,
    });
  };

  const setSharedQuestionAnswers = (answers) => {
    console.log('setSharedQuestionAnswers', answers);
    dispatch({
      type: SET_SHARED_ANSWERS_DATA,
      payload: answers,
    });
  };

  const changeAnswer = (answers, model) => {
    console.log('changeAnswer', answers, model);
    dispatch({
      type: SET_ANSWERS_DATA,
      payload: { data: answers, model },
    });
  };

  const setScore = (score) => {
    dispatch({
      type: SET_SCORE,
      payload: score,
    });
  };

  const changeLastVisitedIndex = (index) => {
    dispatch({
      type: SET_LAST_VISITED_INDEX,
      payload: index,
    });
  };

  return (
    <CalculatorsContext.Provider
      value={{
        state,
        questions: state.questions,
        calcSteps: state.calcSteps,
        localQuestions: state.questions?.local,
        carbonQuestions: state.questions?.carbon,
        globalQuestions: state.questions?.global,
        localAnswers: state.answers?.local,
        carbonAnswers: state.answers?.carbon,
        globalAnswers: state.answers?.global,
        customCO2Answers: state.answers?.customCO2,
        globalInputTypes: state.globalInputTypes,
        calculatorsList: state.calculatorsList,
        questionsMap: state.questionsMap,
        // carbonSources: state.carbonSources,
        // company: state.company,
        fullChartData: state.fullChartData,
        // calculatorAnswers: state.calculatorAnswers,
        // globalAnswers: state.globalAnswers,
        score: state.score,
        lastVisitedIndex: state.lastVisitedIndex,
        // filesList,
        calculatorName: state.calculatorName,
        chartData: state.chartData,
        calculatorCost: state.calculatorCost,
        calculatorPrices: state.calculatorPrices,
        chartCreated: state.chartCreated,
        section: state.section,
        config: state.config,
        sharedQuestionAnswers: state.shares,
        // changeCompanyQuestionsData,
        // changeGlobalQuestionsData,
        changeGlobalInputTypesData,
        setQuestionsData,
        setAnswersData,
        setQuestionsMap,
        changeAnswer,
        // setCalculatorsList,
        // createCalculatorList,
        // changeCalculatorAnswer,
        // changeGlobalCalculatorAnswer,
        isAnyAnswerExists,
        //setDraftData,
        setScore,
        changeLastVisitedIndex,
        // setFilesList,
        // setCalculatorName,
        setCurrentCalcSection,
        setConfig,
        setChartData,
        setSharedQuestionAnswers,
      }}
    >
      {children}
    </CalculatorsContext.Provider>
  );
};

CalculatorsState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CalculatorsState;

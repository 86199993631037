import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'tt-ui-lib/core';
import styles from './SharedAnswers.module.scss';
import {
  DownIcon,
  ArrowAscIcon,
  VerifiedYesIcon,
  VerifiedNoIcon,
  HelpIcon,
  PersonIcon,
  MoreVIcon,
  SearchIcon,
  Select,
} from 'tt-ui-kit';
import { CalculatorsContext } from '../../context';
import { CALC_MODELS } from '../../constants';

const SharedAnswers = () => {
  const { sharedQuestionAnswers, calcSteps, questions } = useContext(CalculatorsContext);
  const [searchValue, setSearchValue] = useState('');
  const [userList, setUserList] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [sharedData, setSharedData] = useState(null);

  const getCarbonData = (question, step) => {
    const { data } = question;
    const { index } = step;
    console.log('getCarbonData', data, index);
    return {};
  };

  const getCustomCo2Data = (question, step) => {
    const { data } = question;
    const { index } = step;
    console.log('getCustomCo2Data', data, index);
    return {};
  };

  const getAdditionalData = (type, params) => {
    switch (type) {
      case CALC_MODELS.CARBON:
        return getCarbonData(params);
      case CALC_MODELS.CUSTOM_CO2:
        return getCustomCo2Data(params);
      default:
        return null;
    }
  };
  useEffect(() => {
    if (!sharedData) return;
    console.log('sharedData', sharedData);
  }, [sharedData]);

  useEffect(() => {
    if (!questions) return;
    console.log('questions', questions);
  }, [questions]);

  useEffect(() => {
    if (!userIds) return;
    console.log('userIds', userIds);
  }, [userIds]);

  useEffect(() => {
    if (!calcSteps || !sharedQuestionAnswers) return;
    const users = sharedQuestionAnswers.map((el) => el.share.userId);

    const uniqueQuestions = sharedQuestionAnswers.reduce((acc, share) => {
      const { questions: sharedQuestions, answers } = share;

      sharedQuestions.forEach((question) => {
        const existingQuestion = acc.find((q) => q.id === question.id);
        const questionStep = calcSteps.find((step) => step.id === question.outerId);
        if (!existingQuestion) {
          // Add the question with its matching answers
          acc.push({
            ...question,
            answers: [
              ...(question?.answers ?? []),
              ...answers.filter((answer) => answer.questionId === question.id),
            ],
            step: questionStep.index,
          });
        } else {
          // Merge answers for the existing question
          existingQuestion.answers.push(
            ...answers.filter((answer) => answer.questionId === question.id)
          );
        }
      });

      return acc;
    }, []);

    const questionTree = uniqueQuestions.reduce((acc, question) => {
      const { type, data } = question;
      const scope = 1;
      return acc;
    }, {});

    setUserIds(users);
    setSharedData(uniqueQuestions);
  }, [calcSteps, sharedQuestionAnswers]);

  return (
    <>
      <div className={styles.answersWrapper}>
        <div className={styles.answersInfoBlock}>
          <div className={styles.answersInfo}>
            <p>
              Your colleagues have provided answers to your question! Review the answers and select
              the one that best addresses your query. Once you&apos;ve made your choice, click the
              &apos;ApplyAnswer&apos; button. The selected answer will be automatically inserted
              into the calculator, where you can still edit it if necessary before finalizing it.
              This feature helps you leverage your colleagues&apos; expertise and complete your
              calculations more efficiently!
            </p>
          </div>
          <div className={styles.answersfiltre}>
            <Select className={styles.filtreSelect} label="Исполнитель" type="default" />
            <div className={styles.searchBox}>
              <div className={styles.searchImg}>
                <SearchIcon style={{ width: 18, height: 18, opacity: 0.8 }} />
              </div>
              <div className={styles.search}>
                <input placeholder="Search..." className={styles.searchInput} value={searchValue} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.answersActionBlock}>
          <div className={styles.scopeList}>
            <div className={styles.scopeBlock}>
              <div className={styles.scopeTitle}>
                <p>Scope 1</p>
                <DownIcon />
              </div>
              <div className={styles.scopeContent}>
                <div className={styles.scopeItem}>
                  <div className={styles.scopeItemInfo}>
                    <VerifiedYesIcon className={styles.verifiedYesIcon} />
                    <p>Business travel-air</p>
                  </div>
                  <Button type="link" className={styles.questionButton}>
                    Go to question
                    <ArrowAscIcon className={styles.questionIcon} />
                  </Button>
                </div>
                <div className={styles.scopeItem}>
                  <div className={styles.scopeItemInfo}>
                    <VerifiedNoIcon className={styles.verifiedNoIcon} />
                    <p>Business travel-air</p>
                  </div>
                  <Button type="link" className={styles.questionButton}>
                    Go to question
                    <ArrowAscIcon className={styles.questionIcon} />
                  </Button>
                </div>
                <div className={styles.scopeItem}>
                  <div className={styles.scopeItemInfo}>
                    <HelpIcon className={styles.helpIcon} />
                    <p>Business travel-air</p>
                  </div>
                  <Button type="link" className={styles.questionButton}>
                    Go to question
                    <ArrowAscIcon className={styles.questionIcon} />
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.scopeBlock}>
              <div className={styles.scopeTitle}>
                <p>Scope 1</p>
                <DownIcon />
              </div>
              <div className={styles.scopeContent}>
                <div className={styles.scopeItem}>
                  <div className={styles.scopeItemInfo}>
                    <VerifiedYesIcon className={styles.verifiedYesIcon} />
                    <p>Business travel-air</p>
                  </div>
                  <Button type="link" className={styles.questionButton}>
                    Go to question
                    <ArrowAscIcon className={styles.questionIcon} />
                  </Button>
                </div>
                <div className={styles.scopeItem}>
                  <div className={styles.scopeItemInfo}>
                    <VerifiedNoIcon className={styles.verifiedNoIcon} />
                    <p>Business travel-air</p>
                  </div>
                  <Button type="link" className={styles.questionButton}>
                    Go to question
                    <ArrowAscIcon className={styles.questionIcon} />
                  </Button>
                </div>
                <div className={styles.scopeItem}>
                  <div className={styles.scopeItemInfo}>
                    <HelpIcon className={styles.helpIcon} />
                    <p>Business travel-air</p>
                  </div>
                  <Button type="link" className={styles.questionButton}>
                    Go to question
                    <ArrowAscIcon className={styles.questionIcon} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.answersList}>
            <div className={styles.answerItem}>
              <div className={styles.answerItemAction}>
                <div className={styles.userData}>
                  <PersonIcon style={{ width: '37px', height: '37px' }} />
                  <div className={styles.userInfo}>
                    <p>Albert Flores</p>
                    <p>должность в компании</p>
                  </div>
                </div>
                <div className={styles.answerAction}>
                  <MoreVIcon style={{ width: '24px', height: '24px' }} />
                </div>
              </div>
              <div className={styles.answercontent}>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className={styles.answerItem}>
              <div className={styles.answerItemAction}>
                <div className={styles.userData}>
                  <PersonIcon style={{ width: '37px', height: '37px' }} />
                  <div className={styles.userInfo}>
                    <p>Albert Flores</p>
                    <p>должность в компании</p>
                  </div>
                </div>
                <div className={styles.answerAction}>
                  <MoreVIcon style={{ width: '24px', height: '24px' }} />
                </div>
              </div>
              <div className={styles.answercontent}>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className={styles.answerItem}>
              <div className={styles.answerItemAction}>
                <div className={styles.userData}>
                  <PersonIcon style={{ width: '37px', height: '37px' }} />
                  <div className={styles.userInfo}>
                    <p>Albert Flores</p>
                    <p>должность в компании</p>
                  </div>
                </div>
                <div className={styles.answerAction}>
                  <MoreVIcon style={{ width: '24px', height: '24px' }} />
                </div>
              </div>
              <div className={styles.answercontent}>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.applyBlock}>
          <Button type="primary">Apply answer</Button>
        </div>
      </div>
    </>
  );
};

export default SharedAnswers;

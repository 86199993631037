/* eslint-disable */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, Route, Routes, useLocation, Navigate, useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';

import { CalculatorsContext, DraftContext, ErrorContext } from '../../context/index.js';
import { CALC_MODELS, ColorScheme } from '../../constants.js';

// import NotFound from "../../../views/notFound/NotFound";

import CarbonCalculator from '../calculators/Carbon/Carbon.jsx';
import CustomCo2 from '../calculators/CustomCo2/CustomCo2.jsx';

import Chart from '../../components/chart/Chart.jsx';
import ProgressCircle from '../../components/progressCircle/ProgressCircle.jsx';
import Pagination from '../../components/pagination/Pagination.jsx';
import CalculationCost from '../../components/calculationCost/CalculationCost.jsx';
import AccordionGroup from '../../components/accordionGroup/AccordionGroup.jsx';
import SelfAssessment from '../../components/selfAssessment/SelfAssessment.jsx';
import SolutionsGlobalModal from '../../components/solutionsModal/SolutionsGlobalModal.jsx';
import AllCarbonQuestionsModal from '../../components/allQuestionsModal/AllCarbonQuestionsModal.jsx';
import PreliminaryResults from '../../components/preliminaryResultModal/PreliminaryResults';

import styles from './CalculatorsLayout.module.scss';

import { Button } from 'tt-ui-lib/core';
import { CheckedIcon } from 'tt-ui-lib/icons';

const CalculatorsLayout = () => {
  const navigate = useNavigate();
  const calculatorContainer = useRef();
  const { calcSteps, isAnyAnswerExists } = useContext(CalculatorsContext);
  const { draft, draftId } = useContext(DraftContext);
  const { setErrorAlert } = useContext(ErrorContext);

  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionCount, setQuestionCount] = useState(1);
  // const [chartIndex, setChartIndex] = useState(null);
  const [chartColor, setChartColor] = useState('#12491D');
  const [chartName, setChartName] = useState(null);
  const [chartPartData, setChartPartData] = useState([]);

  const [accordionItems, setAccordionItems] = useState([]);
  const [descriptionItems, setDescriptionItems] = useState([]);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [allQuestionModalOpened, setAllQuestionModalOpened] = useState(false);
  const [preliminaryModalOpened, setPreliminaryModalOpened] = useState(false);
  const [activeSector, setActiveSector] = useState(null);

  const {
    carbonQuestions,
    carbonAnswers,
    customCO2Answers,
    calculatorsList,
    config,
    setChartData,
  } = useContext(CalculatorsContext);

  if (calcSteps?.length > 0 && !calcSteps.some((s) => s.route === location.pathname))
    navigate('/404');

  const currentStep = calcSteps.find((s) => s.route === location.pathname);

  // init chart data with carbon answers
  useEffect(() => {
    if (!carbonQuestions || !calculatorsList) return;
    const newChartData = [];
    const scopesIndexWithCustom = [0, 1];
    calculatorsList.forEach((model) => {
      const modelConf = config.models.find((m) => m.model === model);
      const { name } = modelConf.texts.en;
      if (model === 'carbon') {
        const carbonChart = carbonQuestions.reduce((acc, scope, scopeIndex) => {
          const children = scope.categories.reduce(
            (acc2, category) => [
              ...acc2,
              {
                name: category.name,
                type: 'category',
                color: ColorScheme[scope.code],
                value: category.questions.filter((q) =>
                  carbonAnswers?.[category.code]?.[q.id]?.some((a) => a.filled)
                ).length,
                total: category.questions.length,
                code: category.code,
              },
            ],
            []
          );
          if (scopesIndexWithCustom.includes(scopeIndex)) {
            children.push({
              name: `${scope.name}: Custom Co2 Items`,
              type: CALC_MODELS.CUSTOM_CO2,
              color: ColorScheme[scope.code],
              value: customCO2Answers?.answers?.[scopeIndex]?.some((a) => a.filled) ? 1 : 0,
              total: 1,
              code: scope.code,
            });
          }
          return [
            ...acc,
            {
              name: scope.name,
              calc: name,
              color: ColorScheme[scope.code],
              value: children.filter((c) => c.value > 0).length,
              total: scope.categories.length + (scopesIndexWithCustom.includes(scopeIndex) ? 1 : 0),
              code: scope.code,
              children,
            },
          ];
        }, []);
        newChartData.push(...carbonChart);
      }
    });
    setChartData(newChartData);
  }, [carbonQuestions, carbonAnswers, calculatorsList, customCO2Answers]);

  // init accordion data
  useEffect(() => {
    if (!carbonQuestions || !config || !calculatorsList) return;
    const accordion = [];
    calculatorsList
      .filter((model) => model != CALC_MODELS.CUSTOM_CO2)
      .forEach((model) => {
        const modelChildren = [];
        const modelConf = config.models.find((m) => m.model === model);
        const { name, info: content } = modelConf.texts.en;
        if (model === 'carbon') {
          const carbonAccordion = carbonQuestions.map((scope) => {
            const children = [];
            children.push({
              expanded: `${scope.name}: Custom Co2 Items` === activeSector,
              name: `${scope.name}: Custom Co2 Items`,
              type: CALC_MODELS.CUSTOM_CO2,
              nested: true,
            });
            scope.categories.forEach((cat) => {
              children.push({
                expanded: cat.name === activeSector,
                name: cat.name,
                code: cat.name,
                content: cat.description,
                nested: true,
              });
            });
            return {
              expanded:
                scope.name === activeSector ||
                scope.categories.some((cat) => cat.name === activeSector),
              name: scope.name,
              calc: name,
              content: scope.description,
              children: [...children],
            };
          });
          modelChildren.push(...carbonAccordion);
        }
        accordion.push({ name, content, expanded: false, children: [...modelChildren] });
      });
    setAccordionItems(accordion);
  }, [carbonQuestions, calculatorsList, config]);

  const openModal = (activeItem) => {
    setActiveSector(activeItem);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setActiveSector(null);
    setIsModalOpened(false);
  };

  const nextPage = () => {
    if (currentStep?.index < calcSteps.length - 1) {
      const nextRoute = calcSteps[currentStep.index + 1].route;
      navigate(nextRoute);
    } else {
      if (isAnyAnswerExists()) {
        navigate(`/buy_credits/${draftId}`);
      } else navigate('/drafts');
    }
  };

  const onNextClick = () => {
    if (currentStep?.scopeIndx != calcSteps[currentStep.index + 1]?.scopeIndx)
      setPreliminaryModalOpened(true);
    else nextPage();
  };

  const closePreliminaryModal = () => {
    setPreliminaryModalOpened(false);
    nextPage();
  };

  const onBackClick = () => {
    if (currentStep.index > 0) {
      navigate(calcSteps[currentStep.index - 1].route);
    } else navigate('/registration');
  };

  const onDescriptionSelect = (selected, name) => {
    const items = [...descriptionItems];
    const oldActive = items.findIndex((v) => v.expanded);

    if (oldActive !== -1) items[oldActive].expanded = false;

    if (selected) {
      const newActive = items.findIndex((v) => v.name === name);

      if (newActive !== -1) items[newActive].expanded = true;
    }
    setDescriptionItems(items);
  };

  return (
    <>
      <Grid
        item
        container
        alignItems="center"
        direction="column"
        className={styles.calculatorContainer}
        ref={calculatorContainer}
      >
        {/* <Grid className={styles.breadcrumps}>
            TransparenTerra&nbsp;&nbsp;▷&nbsp;&nbsp;ESG&nbsp;&nbsp;▷&nbsp;&nbsp;
            <Link to="/">Calculator</Link>
            {accordionItems && accordionItems.length && (
              <span>
                &nbsp;&nbsp;▷&nbsp;&nbsp;
                <Link to={getFirstQuestionUrl()}>{accordionItems[0].name}</Link>
                {accordionItems[0].name}
              </span>
            )}
          </Grid> */}
        <SelfAssessment />

        <Grid item container justifyContent="center" className={styles.content}>
          <ProgressCircle current={questionIndex} count={calcSteps?.length} color={chartColor} />
          <div className={styles.chartArea}>
            <Chart
              chartData={chartPartData}
              hasDesktopFormat={false}
              onChartClick={() => openModal(chartName)}
              activeItem={activeSector}
            />
          </div>
          <Grid item container alignItems="center" className={styles.infoArea}>
            <AccordionGroup items={descriptionItems} onAccordionClick={onDescriptionSelect} />
          </Grid>
          <div className={styles.middleContainer}>
            {currentStep && (
              <>
                {currentStep.route === location.pathname &&
                  currentStep.model === CALC_MODELS.CARBON && (
                    <CarbonCalculator
                      setDescriptionItems={setDescriptionItems}
                      setChartPartData={setChartPartData}
                    />
                  )}
                {currentStep.route === location.pathname &&
                  currentStep.model === CALC_MODELS.CUSTOM_CO2 && (
                    <CustomCo2 setChartPartData={setChartPartData} />
                  )}
              </>
            )}
            <div className={styles.infoWrapper}>
              <div className={styles.lastupdateInfo}>
                <CheckedIcon className={styles.checkIcon} />
                <p>Latest update: {new Date(draft.updatedAt).toLocaleDateString()}</p>
              </div>
              <div className={styles.paginationDescktop}>
                <Pagination activePage={currentStep?.index + 1} pagesCount={calcSteps.length} />
              </div>
              <div className={styles.calculationCostWrapper}>
                <CalculationCost isItemsComponents={false} />
              </div>
            </div>
            <div className={styles.navigationButtons}>
              <Button type="outlined" onClick={() => onBackClick()}>
                BACK
              </Button>
              <Button
                type="outlined"
                onClick={() => setAllQuestionModalOpened(true)}
                className={styles.viewDescktopButton}
              >
                view all questions
              </Button>
              <div className={styles.paginationMobile}>
                <Pagination activePage={currentStep?.index + 1} pagesCount={calcSteps.length} />
              </div>
              <Button type="primary" onClick={() => onNextClick()}>
                {currentStep?.index < calcSteps.length - 1 ? `NEXT` : `FINISH`}
              </Button>
            </div>
            <div className={styles.viewMobileButton}>
              <Button type="outlined" onClick={() => setAllQuestionModalOpened(true)}>
                view all questions
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
      <SolutionsGlobalModal
        opened={isModalOpened}
        closeModalFunc={closeModal}
        activeItem={activeSector}
        accordionItems={accordionItems}
      />
      {allQuestionModalOpened && (
        <AllCarbonQuestionsModal
          opened={allQuestionModalOpened}
          closeModalFunc={() => setAllQuestionModalOpened(false)}
          currentIndex={currentStep?.index}
        />
      )}
      {preliminaryModalOpened && (
        <PreliminaryResults
          opened={preliminaryModalOpened}
          closeModalFunc={closePreliminaryModal}
          draftId={draftId}
          scopeIndex={currentStep?.scopeIndx}
        />
      )}
    </>
  );
};

export default CalculatorsLayout;

/* eslint-disable */

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Button } from '@mui/material';
import UiDatePicker from '../../components/uiDatePicker/UiDatePicker';
import styles from './RegistrationForm.module.scss';

import UiTextField from '../../components/uiTextField/UiTextField';
import FileUploader from '../../components/fileUploader/FileUploader';
import MuiSelect from '../../components/muiSelect/MuiSelect';
import { CalculatorsContext } from '../../context/index';

const RegistrationForm = ({
  onChange,
  onChangeDate,
  openDisclaimer,
  numberFilter,
  data,
  // years,
  reportYear,
  dateDisable,
  factorList,
}) => {
  const { calculatorName } = useContext(CalculatorsContext);

  return (
    <>
      <Button
        className={styles.disclaimerBtn}
        size="small"
        color="lightBlue"
        onClick={openDisclaimer}
      >
        Financial data*
      </Button>
      <UiTextField
        className={clsx(styles.input, styles.wide)}
        label="Annual Turnover (USD) *"
        name="annualTurnover"
        filter={numberFilter}
        value={((data && data.annualTurnover) ?? '').toString()}
        onChange={onChange}
        maxNumber={2147483647}
      />
      <UiTextField
        className={clsx(styles.input, styles.normal)}
        label="Net Profit (USD) *"
        name="netProfit"
        filter={numberFilter}
        value={((data && data.netProfit) ?? '').toString()}
        onChange={onChange}
        maxNumber={2147483647}
      />
      <UiTextField
        className={clsx(styles.input, styles.normal)}
        label="Year taxes paid for the year (USD) *"
        name="yearTaxes"
        filter={numberFilter}
        value={((data && data.yearTaxes) ?? '').toString()}
        onChange={onChange}
        maxNumber={2147483647}
      />
      <UiTextField
        className={clsx(styles.input, styles.wide)}
        label="Number of unique products *"
        name="numberUniqueProducts"
        filter={numberFilter}
        value={((data && data.numberUniqueProducts) ?? '').toString()}
        onChange={onChange}
        maxNumber={2147483647}
      />
      <UiTextField
        className={clsx(styles.input, styles.normal)}
        label="The number of employees *"
        name="employeesNumber"
        filter={numberFilter}
        value={((data && data.employeesNumber) ?? '').toString()}
        onChange={onChange}
        maxNumber={2147483647}
      />
      <UiTextField
        className={clsx(styles.input, styles.normal)}
        label="Annual productivity for each position *"
        name="annualProductivityEachPosition"
        filter={numberFilter}
        value={((data && data.annualProductivityEachPosition) ?? '').toString()}
        onChange={onChange}
        maxNumber={2147483647}
      />
      <UiDatePicker
        className={clsx(styles.input, styles.wide)}
        label="Reporting year *"
        name="periodReportDate"
        value={reportYear ? new Date(reportYear, 1, 1) : null}
        onChange={onChangeDate}
        minDate={new Date(2000, 1, 1)}
        maxDate={new Date(new Date().getFullYear(), 11, 31)}
        onlyYear
        disabled={dateDisable}
        required
      />
      <MuiSelect
        className={clsx(styles.input, styles.wide)}
        label="Coversion factors"
        name="ukGhgCollectionId"
        value={data.ukGhgCollectionId ?? ''}
        onChange={onChange}
        renderValue={(value) => factorList.find((item) => item.id === value)?.name}
        items={factorList?.map((item) => item.id) ?? []}
      />
      <div>
        <FileUploader model={calculatorName} questionCode="registration" disabled={!data.id} />
      </div>
    </>
  );
};

RegistrationForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  openDisclaimer: PropTypes.func.isRequired,
  numberFilter: PropTypes.instanceOf(RegExp),
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  years: PropTypes.array,
  reportYear: PropTypes.number,
  dateDisable: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  factorList: PropTypes.array,
};

export default RegistrationForm;

/* eslint-disable */

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Modal } from 'tt-ui-lib/core';
import Chart from '../chart/Chart';
import styles from './PreliminaryResults.module.scss';
import { CalculatorsContext } from '../../context';
import { preliminaryAssessment } from '../../api/rest/list';

const PreliminaryResults = ({ opened, closeModalFunc, scopeIndex, draftId }) => {
  const [result, setResult] = useState(null);
  const { chartData, carbonQuestions } = useContext(CalculatorsContext);

  useEffect(() => {
    if (opened) {
      preliminaryAssessment({ id: draftId }).then((res) => {
        const { data, success } = res.data;
        if (success) setResult(data);
      });
    }
    return () => {
      setResult(null);
    };
  }, [opened]);

  return (
    <Modal
      onClose={closeModalFunc}
      open={opened}
      containerClassName={styles.modalRoot}
      className={styles.modal}
      fullScreenOnResponse
      closeOnlyByControls
      title={`${carbonQuestions[scopeIndex]?.name} preliminary results`}
    >
      <div>
        <div className={styles.wrap}>{`Your score - ${Math.round(result?.carbon ?? 0)}`}</div>
        <div className={styles.chartArea}>
          <Chart
            chartData={chartData}
            hasDesktopFormat={false}
            score={result?.carbon}
            isCO2score={true}
          />
        </div>
      </div>
    </Modal>
  );
};

PreliminaryResults.propTypes = {
  scope: PropTypes.number,
  opened: PropTypes.bool,
  closeModalFunc: PropTypes.func,
  draftId: PropTypes.string,
};

export default PreliminaryResults;

import React, { useState } from 'react';
import styles from './CustomCo2Item.module.scss';
import AddIcon from '@mui/icons-material/Add';
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  ListItemIcon,
  TextField,
  InputAdornment,
} from '@mui/material';
import SupplierModal from '../../modals/SupplierModal/SupplierModal';
import ComponentItemModal from '../../modals/ComponentItemModal/ComponentItemModal';

const CustomCo2Item = (props) => {
  const { index, onChange, activity, suppliers, itemComponents } = props;

  const [SuppliermodalVisible, setSuppliermodalVisible] = useState(false);
  const [itemComponentsModalVisible, setItemComponentsModalVisible] = useState(false);

  const handleChangeQuantity = (event) => {
    const rawValue = event.target.value;
    const numericValue = Number(rawValue);

    if (numericValue > 999999999) return;
    if (numericValue < 0) return;

    onChange(rawValue, 'quantity');
  };

  const handleChangeFootprint = (event) => {
    const rawValue = event.target.value;
    const numericValue = Number(rawValue);

    if (numericValue > 999999999) return;
    if (numericValue < 0) return;
    onChange(rawValue, 'footprint');
  };

  const handleChangeItem = (event) => onChange(event?.target?.value, 'item');
  const handleChangeSupplier = (event) => onChange(event.target.value, 'supplier');
  const onCheckBoxChange = (event) => onChange(event.target.checked, 'isOffset');

  const onRenderValue = ({ value, label }) =>
    value ? (
      typeof value === 'string' || typeof value === 'number' ? (
        <Typography className={styles.selectPlaceholder} variant="body1" component="span">
          {value}
        </Typography>
      ) : (
        value
      )
    ) : (
      <Typography className={styles.selectPlaceholder} variant="body2" color="textSecondary">
        {label}
      </Typography>
    );

  return (
    <>
      <div key={`CustomCo2Item${index}`} className={styles.customCo2Item}>
        <div className={styles.itemsTitle}>
          <p>{`Item/Component №${index + 1}`}</p>
        </div>
        <div className={styles.inputsBlock}>
          <div className={styles.inputRow}>
            <FormControl fullWidth className={styles.customCo2Input}>
              <Select
                value={activity.item?.name ?? ''}
                displayEmpty
                onChange={handleChangeItem}
                renderValue={(value) => onRenderValue({ value, label: 'item/components' })}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #EFEFEF',
                    borderRadius: '12px',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#EFEFEF',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#EFEFEF',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxWidth: 'calc(100% - 60px)',
                    },
                  },
                  sx: {
                    '& .MuiPaper-root': {
                      maxHeight: '200px',
                      border: '1px solid #EFEFEF',
                      borderRadius: '12px',
                      boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.04)',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#ccc transparent',
                    },
                    '& .MuiMenuItem-root': {
                      borderRadius: '5px',
                    },
                    '& .MuiMenu-list': {
                      padding: '12px 20px',
                    },
                  },
                }}
              >
                <Typography className={styles.listHeader} variant="body2" color="textSecondary">
                  Component/item name
                </Typography>

                {itemComponents.map((item) => (
                  <MenuItem
                    key={item.name}
                    value={item}
                    sx={{
                      px: '20px',
                      py: '6px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'block',
                      maxWidth: '100%',
                    }}
                  >
                    {item.name}
                  </MenuItem>
                ))}

                <MenuItem
                  onClick={() => setItemComponentsModalVisible(true)}
                  sx={{ color: '#18397A', px: '20px', py: '6px' }}
                >
                  <ListItemIcon sx={{ color: '#18397A' }}>
                    <AddIcon />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ color: '#18397A' }}>
                    Add component/item
                  </Typography>
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="options"
              className={styles.costInput}
              value={activity.quantity ?? ''}
              label="Quantity"
              data-type="unit_quantity"
              type="number"
              onChange={handleChangeQuantity}
              onKeyDown={(e) => {
                const isControlKey = e.ctrlKey || e.metaKey;
                if (
                  !isControlKey &&
                  (e.key === ' ' ||
                    (!/[0-9]/.test(e.key) &&
                      !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter'].includes(
                        e.key
                      )))
                ) {
                  e.preventDefault();
                }
              }}
              InputProps={{
                sx: {
                  border: '1px solid #EFEFEF',
                  borderRadius: '12px',
                  '&:hover, &.Mui-focused': {
                    borderColor: '#EFEFEF !important',
                    boxShadow: 'none !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    display: 'none',
                  },
                  '& input': {
                    backgroundColor: 'white',
                  },
                },
                style: { appearance: 'textfield' },
                inputProps: {
                  min: 0,
                  max: 999999999,
                  step: 'any',
                  onWheel: (e) => {
                    e.target.blur();
                  },
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <>
                      <Select
                        style={{ display: 'flex' }}
                        sx={{
                          backgroundColor: 'transparent',
                          '.MuiOutlinedInput-notchedOutline': {
                            border: '0 !important',
                          },
                        }}
                        MenuProps={{
                          sx: {
                            '& .MuiPaper-root': {
                              maxHeight: '200px',
                              border: '1px solid #EFEFEF',
                              borderRadius: '12px',
                              boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.04)',
                              padding: '5px',
                            },
                            '& .MuiMenuItem-root': {
                              borderRadius: '5px',
                              padding: '5px',
                            },
                            '& .MuiMenu-list': {
                              padding: '5px',
                            },
                          },
                        }}
                        value="kg"
                        renderValue={() => (
                          <div style={{ display: 'flex' }}>
                            <p>kg</p>
                          </div>
                        )}
                      >
                        <MenuItem>
                          <p>kg</p>
                        </MenuItem>
                      </Select>
                    </>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={styles.inputRow}>
            <FormControl fullWidth className={styles.customCo2Input}>
              <Select
                value={
                  activity.supplier
                    ? `${activity.supplier.firstName} ${activity.supplier.lastName}`
                    : ''
                }
                displayEmpty
                onChange={handleChangeSupplier}
                renderValue={(value) => onRenderValue({ value, label: 'Supplier' })}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #EFEFEF',
                    borderRadius: '12px',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#EFEFEF',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#EFEFEF',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxWidth: 'calc(100% - 60px)',
                    },
                  },
                  sx: {
                    '& .MuiPaper-root': {
                      maxHeight: '200px',
                      border: '1px solid #EFEFEF',
                      borderRadius: '12px',
                      boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.04)',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#ccc transparent',
                    },
                    '& .MuiMenuItem-root': {
                      borderRadius: '5px',
                    },
                    '& .MuiMenu-list': {
                      padding: '12px 20px',
                    },
                  },
                }}
              >
                <Typography className={styles.listHeader} variant="body2" color="textSecondary">
                  Supplier
                </Typography>

                {suppliers.map((item) => (
                  <MenuItem
                    key={`${item.firstName} ${item.lastName}`}
                    value={item}
                    sx={{
                      px: '20px',
                      py: '6px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'block',
                      maxWidth: '100%',
                    }}
                  >
                    {`${item.firstName} ${item.lastName}`}
                  </MenuItem>
                ))}

                <MenuItem
                  onClick={() => setSuppliermodalVisible(true)}
                  sx={{ color: '#18397A', px: '20px', py: '6px' }}
                >
                  <ListItemIcon sx={{ color: '#18397A' }}>
                    <AddIcon />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ color: '#18397A' }}>
                    Invite supplier
                  </Typography>
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="options"
              className={styles.costInput}
              value={activity.footprint ?? ''}
              label="Component Carbon Footprint"
              data-type="footprint"
              type="number"
              onChange={handleChangeFootprint}
              onKeyDown={(e) => {
                const isControlKey = e.ctrlKey || e.metaKey;
                if (
                  !isControlKey &&
                  (e.key === ' ' ||
                    (!/[0-9]/.test(e.key) &&
                      !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter'].includes(
                        e.key
                      )))
                ) {
                  e.preventDefault();
                }
              }}
              InputLabelProps={{
                sx: {
                  maxWidth: 'calc(100% - 140px)',
                },
              }}
              InputProps={{
                sx: {
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #EFEFEF',
                    borderRadius: '12px',
                  },
                },
                inputProps: {
                  min: 0,
                  max: 999999999,
                  step: 'any',
                  onWheel: (e) => {
                    e.target.blur();
                  },
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <>
                      <Select
                        style={{ display: 'flex' }}
                        sx={{
                          backgroundColor: 'transparent',
                          '.MuiOutlinedInput-notchedOutline': {
                            border: '0 !important',
                          },
                        }}
                        MenuProps={{
                          sx: {
                            '& .MuiPaper-root': {
                              maxHeight: '200px',
                              border: '1px solid #EFEFEF',
                              borderRadius: '12px',
                              boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.04)',
                              padding: '5px',
                            },
                            '& .MuiMenuItem-root': {
                              borderRadius: '5px',
                              padding: '5px',
                            },
                            '& .MuiMenu-list': {
                              padding: '5px',
                            },
                          },
                        }}
                        value="kg"
                        renderValue={() => (
                          <div style={{ display: 'flex' }}>
                            <p>kg CO2</p>
                          </div>
                        )}
                      >
                        <MenuItem>
                          <p>kg</p>
                        </MenuItem>
                      </Select>
                    </>
                  </InputAdornment>
                ),
              }}
            />
            <div className={styles.checkboxContainer}>
              <input type="checkbox" id="offset" onChange={onCheckBoxChange} />
              <label htmlFor="offset">It has been offset</label>
            </div>
          </div>
        </div>
      </div>
      <ComponentItemModal
        visible={itemComponentsModalVisible}
        onClose={() => setItemComponentsModalVisible(false)}
        setItemComponents={(value) => onChange(value, 'item', true)}
      />
      <SupplierModal
        visible={SuppliermodalVisible}
        onClose={() => setSuppliermodalVisible(false)}
        setSuppliers={(value) => onChange(value, 'supplier', true)}
        supplier={suppliers}
      />
    </>
  );
};

export default CustomCo2Item;

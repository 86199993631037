/* eslint-disable */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { CalculatorsContext, DraftContext, ErrorContext } from '../../context/index.js';

import { getCalcAnswers, getCalcQuestions, getShares } from '../../api/rest/list.js';
import Loading from '../../components/loading/Loading.jsx';
import CalculatorsLayout from '../../views/calculatorsLayout/CalculatorsLayout.jsx';
import SharedAnswers from '../../views/answers/SharedAnswer.jsx';

const initialLoaderFlags = {
  questions: false,
  answers: false,
};

const CalculatorsRouter = () => {
  /* TODO сделать переход на последний вопрос */
  // const { pathname, state } = useLocation();
  const { calculatorsList, setQuestionsData, setAnswersData, setSharedQuestionAnswers } =
    useContext(CalculatorsContext);
  const { draft, draftId } = useContext(DraftContext);
  const { setErrorAlert } = useContext(ErrorContext);

  //-----------------------------------
  const [loading, setLoading] = useState(0);

  const loadingRef = useRef(null);

  const increaseLoading = (stepName) => {
    if (loadingRef.current) {
      loadingRef.current.increaseLoading(stepName);
    }
  };
  //-----------------------------------

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        // Run all requests sequentially and update state safely
        const requests = calculatorsList.map(async (model) => {
          const param = { model, calculator_id: draft.id };
          const res = await getCalcAnswers(param); // Call API
          const { data } = res.data;
          return { [model]: JSON.parse(data?.[model] ?? '{}') }; // Return the result in an object
        });

        // Wait for all requests to complete
        const results = await Promise.all(requests);

        // Merge all results into a single object
        const mergedAnswers = results.reduce((acc, current) => {
          return { ...acc, ...current };
        }, {});
        // Update the context state with the final merged object
        setAnswersData(mergedAnswers);
      } catch (error) {
        setErrorAlert('Failed to load answers:', null, error);
      }
    };

    if (!calculatorsList?.length || !draft) return;
    fetchAnswers().then(() => increaseLoading('answers'));
  }, [calculatorsList, draft]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        // Run all requests sequentially and update state safely
        const requests = calculatorsList.map(async (model) => {
          const param = { model };
          if (model === 'carbon') param.collection_id = draft.ukGhgCollectionId;
          const res = await getCalcQuestions(param); // Call API
          const { data } = res.data;
          return { ...data }; // Return the result in an object
        });

        // Wait for all requests to complete
        const results = await Promise.all(requests);
        const mergedQuestions = results.reduce((acc, current) => {
          return { ...acc, ...current };
        }, {});

        // Update the context state with the final merged object
        setQuestionsData(mergedQuestions);
      } catch (error) {
        setErrorAlert('Failed to load questions:', null, error);
      }
    };

    if (!calculatorsList?.length || !draft) return;
    fetchQuestions().finally(() => increaseLoading('questions'));
  }, [calculatorsList, draft]);

  useEffect(() => {
    getShares({ calculator_id: draftId }).then((res) => {
      const { data, success } = res.data;
      if (success) {
        console.log('getShares', data);
        setSharedQuestionAnswers(data);
      }
    });
  }, [draftId]);

  return (
    <>
      <Routes>
        <Route exact path="/carbon" element={<Navigate to="1" />} />
        <Route exact path="/carbon/:idx" element={<CalculatorsLayout />} />
        <Route exact path="answers" element={<SharedAnswers />} />
      </Routes>
      <Loading
        ref={loadingRef}
        initialLoaderFlags={initialLoaderFlags}
        setLoading={setLoading}
        loading={loading}
      />
    </>
  );
};

export default CalculatorsRouter;

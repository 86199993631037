/* eslint-disable */

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import { Modal, Typography } from 'tt-ui-kit';
import { Accordion, AccordionDetails, AccordionSummary, styled } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Input, Switch } from 'tt-ui-lib/core';
import styles from './AllCarbonQuestionsModal.module.scss';
import { ReactComponent as AnsveredSvg } from '../../assets/icons/questionAnsvered.svg';
import { CalculatorsContext } from '../../context';
import { getCarbonLabel, getCustomCO2Label } from '../../utils';
import { CheckedIcon } from 'tt-ui-kit';
const CustomizedAccordion = styled(Accordion)(() => ({
  '.MuiAccordionDetails-root': {
    padding: 0,
    maxHeight: 'none',
  },
  '&.MuiAccordion-root': {
    borderRadius: '12px',
    padding: '16px',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
    margin: '0px 20px 20px 20px',
  },
  '&:before': {
    backgroundColor: 'white',
  },
  boxShadow: 'none',
}));

const AllCarbonQuestionsModal = ({ opened, closeModalFunc, currentIndex }) => {
  const navigate = useNavigate();
  const { carbonAnswers, carbonQuestions, customCO2Answers, calcSteps } =
    useContext(CalculatorsContext);

  const [inputValue, setInputValue] = useState('');
  const [questionsOnly, setQuestionsOnly] = useState(false);
  const [questionsAnswers, setQuestionsAnswers] = useState([]);
  const [filtered, setFiltered] = useState(null);

  const searchText = (value) => {
    setInputValue(value);
  };

  useEffect(() => {
    if (!opened) {
      setInputValue('');
    }
  }, [opened]);

  useEffect(() => {
    if (!carbonAnswers || !carbonQuestions) return;

    const questionCollection = [];

    calcSteps.forEach((step) => {
      try {
        const currentScope = carbonQuestions[step.scopeIndx];
        if (!questionCollection.some((scope) => scope.code === currentScope.code)) {
          questionCollection.push({
            code: currentScope.code,
            description: currentScope.description,
            name: currentScope.name,
            categories: [],
            questions: [],
          });
        }
        const collectionScope = questionCollection.find(
          (scope) => scope.code === currentScope.code
        );

        if (step.model === 'customCO2') {
          const customCO2Answer = customCO2Answers?.answers?.[step.scopeIndx];
          const answers = customCO2Answer?.map((answer, indx) => ({
            footprint: answer.footprint,
            quantity: answer.quantity,
            item: answer?.item?.name,
            supplier: `${answer?.supplier?.firstName} ${answer?.supplier?.lastName}`,
            name: `Activity ${indx + 1}`,
          }));

          collectionScope.questions.push({
            name: `Custom CO2 for ${currentScope.name}`,
            id: `customCO2-${collectionScope.code}`,
            route: step.route,
            answers: answers ?? [],
            fields: ['supplier', 'item', 'quantity', 'footprint'],
            filled: !!answers?.length,
            model: step.model,
            current: currentIndex === step.index,
          });
        }

        if (step.model === 'carbon') {
          const currentCategory = currentScope.categories[step.categoryIndx];
          if (!collectionScope.categories.some((cat) => cat.code === currentCategory.code)) {
            collectionScope.categories.push({
              code: currentCategory.code,
              name: currentCategory.name,
              questions: [],
            });
          }
          const collectionCategory = collectionScope.categories.find(
            (cat) => cat.code === currentCategory.code
          );

          const currentQuestion = currentCategory?.questions[step.questionIndx];
          const currentAnswers =
            (carbonAnswers[collectionCategory.code]?.[currentQuestion.id] ?? []).filter(
              (answer) => answer.filled
            ) ?? [];
          const answers = currentAnswers.map((answer, indx) => ({
            name: `Activity ${indx + 1}`,
            ...Object.keys(answer?.meta).reduce(
              (acc, key) => ({
                ...acc,
                [key]: answer.meta[key],
              }),
              {}
            ),
            data: answer.data,
          }));

          collectionCategory.questions.push({
            name: currentQuestion.name,
            id: currentQuestion.id,
            model: step.model,
            route: step.route,
            fields: currentQuestion.fields,
            filled:
              carbonAnswers[collectionCategory.code]?.[currentQuestion.id]?.some(
                (answer) => answer.filled
              ) ?? false,
            answers: answers ?? [],
            current: currentIndex === step.index,
          });
        }
      } catch (e) {
        console.error(e, step);
        throw e;
      }
    });

    setQuestionsAnswers(questionCollection);
    return () => {
      setQuestionsAnswers([]);
    };
  }, [carbonAnswers, carbonQuestions]);

  useEffect(() => {
    if (inputValue) {
      const newFiltered = questionsAnswers
        .map((scope) => {
          return {
            ...scope,
            categories: scope.categories
              .map((category) => {
                return {
                  ...category,
                  questions: category.questions.filter((question) => {
                    const values = question.answers.reduce(
                      (acc, answer) => [
                        ...acc,
                        ...Object.keys(answer)
                          .filter((key) => !['name', 'route'].includes(key))
                          .map((key) => answer[key]),
                      ],
                      []
                    );
                    return (
                      question.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                      values.join(' ').toLowerCase().includes(inputValue.toLowerCase())
                    );
                  }),
                };
              })
              .filter((category) => category.questions.length),
            questions: scope.questions.filter((question) => {
              const values = question.answers.reduce(
                (acc, answer) => [
                  ...acc,
                  ...Object.keys(answer)
                    .filter((key) => !['name', 'route'].includes(key))
                    .map((key) => answer[key]),
                ],
                []
              );
              return (
                question.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                values.join(' ').toLowerCase().includes(inputValue.toLowerCase())
              );
            }),
          };
        })
        .filter((scope) => scope.categories.length || scope.questions.length);
      setFiltered(newFiltered);
    } else {
      setFiltered(questionsAnswers);
    }

    return () => {
      setFiltered(questionsAnswers);
    };
  }, [inputValue, questionsAnswers]);

  const onLinkClick = (route) => {
    closeModalFunc();
    navigate(route);
  };

  const renderCO2Question = (question) => (
    <div key={question.id} className={styles.container}>
      <div className={styles.sourceBlock}>
        <div className={styles.headSourceBlock}>
          <div className={styles.questionBlock}>
            <span>
              <AnsveredSvg
                className={clsx(
                  styles.qbuttonsvg,
                  { [styles.required]: !question?.filled },
                  { [styles.answered]: question?.filled }
                )}
              />
            </span>
            <Highlighter
              highlightClassName={styles.hlight}
              searchWords={[inputValue]}
              textToHighlight={question.name}
              unhighlightClassName={styles.questionTitle}
            />
          </div>
          {!questionsOnly && !question.answers.length && <div>No answer available</div>}
          {!question.current && (
            <Button
              className={styles.questionLink}
              type="link"
              onClick={() => onLinkClick(question.route)}
            >
              Go to question
              <ArrowOutwardIcon />
            </Button>
          )}
        </div>
        {!questionsOnly && (
          <>
            {question.answers.length > 0 && (
              <div className={styles.componentsContainer}>
                {question.answers.map((answer, idx) => (
                  <div className={styles.componentBlock} key={`${idx}`}>
                    <div className={styles.componentNum}>{answer.name}:</div>
                    <div className={styles.componentDetails}>
                      {question.fields.map((key) => (
                        <div key={`key-${question.id}${key}${idx}`}>
                          {getCustomCO2Label(key)}:{' '}
                          <Highlighter
                            highlightClassName={styles.hlight}
                            searchWords={[inputValue]}
                            textToHighlight={`${answer[key] ?? '-'}`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );

  const renderCarbonQuestion = (question) => (
    <div key={question.id} className={styles.container}>
      <div className={styles.sourceBlock}>
        <div className={styles.headSourceBlock}>
          <div className={styles.questionBlock}>
            <span>
              <AnsveredSvg
                className={clsx(
                  styles.qbuttonsvg,
                  { [styles.required]: !question?.filled },
                  { [styles.answered]: question?.filled }
                )}
              />
            </span>
            <Highlighter
              highlightClassName={styles.hlight}
              searchWords={[inputValue]}
              textToHighlight={question.name}
              unhighlightClassName={styles.questionTitle}
            />
          </div>
          {!questionsOnly && !question.answers.length && <div>No answer available</div>}
          {!question.current ? (
            <Button
              className={styles.questionLink}
              type="link"
              onClick={() => onLinkClick(question.route)}
            >
              Go to question
              <ArrowOutwardIcon />
            </Button>
          ) : !questionsOnly && !question.answers.length ? (
            <div className={styles.questionTitleCurrent}>
              <CheckedIcon />
              <p>Current</p>
            </div>
          ) : null}
        </div>
        {!questionsOnly && (
          <>
            {question.answers.length > 0 && (
              <div className={styles.componentsContainer}>
                {question.answers.map((answer, idx) => (
                  <div className={styles.componentBlock} key={`${idx}`}>
                    <div className={styles.componentNum}>{answer.name}:</div>
                    {question?.fields && question?.fields.length && (
                      <div className={styles.componentDetails}>
                        {question.fields.map((field) => (
                          <div key={answer[field]}>
                            {getCarbonLabel(field)}:{' '}
                            <Highlighter
                              highlightClassName={styles.hlight}
                              searchWords={[inputValue]}
                              textToHighlight={`${answer[field]}`}
                            />
                          </div>
                        ))}
                        <div>
                          Data:{' '}
                          <Highlighter
                            highlightClassName={styles.hlight}
                            searchWords={[inputValue]}
                            textToHighlight={`${answer.data}`}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        <div className={styles.infoSourceBlock}>
          <div></div>
          {question.current && question.answers.length > 0 && (
            <div className={styles.questionTitleCurrent}>
              <CheckedIcon />
              <p>Current</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const renderQuestions = (questions) =>
    questions.map((question) => {
      if (question.model === 'carbon') {
        return renderCarbonQuestion(question);
      }
      if (question.model === 'customCO2') {
        return renderCO2Question(question);
      }
    });

  return (
    <Modal
      title={questionsOnly ? 'Preview all questions' : 'Preview all answers'}
      onClose={closeModalFunc}
      open={opened}
      containerClassName={styles.modalRoot}
      className={styles.modal}
      fullScreenOnResponse
      closeOnlyByControls
      sx={{
        padding: '0 !important',
        '._3JyWh': {
          padding: '24px 40px 0px 40px !important',
        },
        '._-luRu': {
          margin: '16px 0',
        },
      }}
    >
      <div className={styles.companyMainFrame}>
        <div className={styles.wrap}>
          <div className={styles.filterRow}>
            <Input
              placeholder="Search"
              value={inputValue}
              className={styles.searchFilterInput}
              onChange={(e) => searchText(e.target.value)}
            />
            <Switch onChange={() => setQuestionsOnly((value) => !value)} value={!questionsOnly} />
            <Typography variant="text" component="div">
              My answers
            </Typography>
          </div>
          {filtered?.length > 0 &&
            filtered.map((scope) => (
              <CustomizedAccordion
                key={scope.id}
                sx={{
                  borderRadius: '16px',
                  padding: '0 !important',
                  margin: '0px 0px 16px 0px !important',
                  '&.MuiAccordionSummary-root': {
                    margin: '12px 10px !important',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    padding: '0px',
                    margin: '20px 20px !important',
                  }}
                >
                  <div className={styles.summary}>
                    <div className={styles.title}>{scope.name}</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    marginTop: '10px',
                    padding: '20px',
                    overflow: 'visible !important',
                  }}
                >
                  {scope.questions && renderQuestions(scope.questions)}
                  {scope.categories &&
                    scope.categories.map((category) => (
                      <CustomizedAccordion key={category.code}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{ padding: '0px', margin: '0px' }}
                        >
                          <div className={styles.summary}>
                            <div className={styles.title}>{category.name}</div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          {category.questions && renderQuestions(category.questions)}
                        </AccordionDetails>
                      </CustomizedAccordion>
                    ))}
                </AccordionDetails>
              </CustomizedAccordion>
            ))}
          {!filtered?.length && <div className={styles.noResults}>No results found</div>}
        </div>
      </div>
    </Modal>
  );
};

AllCarbonQuestionsModal.propTypes = {
  currentIndex: PropTypes.string,
  opened: PropTypes.bool,
  closeModalFunc: PropTypes.func,
};

export default AllCarbonQuestionsModal;
